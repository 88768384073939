import { useState, useEffect } from 'react'

const ExtensionDetector = () => {
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(false)

    useEffect(() => {
        const marker = document.getElementById("track-email-marker");
        if (marker) {
            setIsExtensionInstalled(true)
        } else {
            setIsExtensionInstalled(false)
        }
    }, []);

    if (!isExtensionInstalled) {
        return (
            <div className="bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                <span className="block sm:inline">
                    Please install Track Email extension to enable all features.
                    <a
                        href="YOUR_EXTENSION_STORE_URL"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2 text-red-700 underline hover:no-underline"
                    >
                        Install now
                    </a>
                </span>
            </div>
        )
    }

    return null
}

export default ExtensionDetector