import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import hooks
import './TrackingDetail.css';
import { getEmailTrackingById } from '../../../../api/auth';

const TrackingDetail = () => {
    const { emailId } = useParams(); // Get the email ID from the URL
    const navigate = useNavigate(); // Initialize navigate
    const [emailTracking, setEmailTracking] = useState(null);

    useEffect(() => {
        const fetchEmailTracking = async () => {
            try {
                getEmailTrackingById(emailId).then((response) => {
                    setEmailTracking(response);
                });
            } catch (err) {
                console.error('Failed to fetch email tracking:', err);
            }
        };

        fetchEmailTracking();
    }, [emailId]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <div className="tracking-detail">
            <button onClick={() => navigate('/dashboard/email-tracking')}>Back</button> {/* Back button */}
            {emailTracking ? (
                <><div className="tracking-detail-header">
                    <h2>{emailTracking.email_subject}</h2>
                </div><div className="tracking-summary">
                        <div>
                            <strong>Recipients:</strong> <span>{emailTracking.recipients.map(r => r.email).join(', ')}</span>
                        </div>
                        <div>
                            <strong>Send date:</strong> <span>{formatDate(emailTracking.send_date)}</span>
                        </div>
                        <div>
                            <strong>Activity:</strong> <span>{emailTracking.opens_count} opens</span>
                        </div>
                    </div>
                    {/* <div className="tracking-options">
                        <button className="upgrade-button">Link tracking <span className="upgrade-label">Upgrade</span></button>
                    </div> */}
                    <div className="email-activity">
                        <h3>Email Activity</h3>
                        {emailTracking.activities.map((activity, index) => (
                            activity.type === "open" && <div key={index} className="activity-item">
                            <span className="activity-icon">👁️</span>
                            <span>
                            Opened email {formatDate(activity.created_at)} by {emailTracking.recipients[0].email}
                            </span>
                        </div>
                        ))}
                    </div></>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default TrackingDetail;